import {useContext, useEffect, useState} from 'react'
import BusCollectionContext from "../../store/BusCollection";
import {useTranslation} from "react-i18next";
import {api} from "../../libs/api";
import './Card.module.css'
import {busColor} from "../../utils/busColor";
import CardComponent from './CardComponent';
// import BorderGradientButton from "../buttons/BorderGradientButton";
// import {Drawer} from "@material-tailwind/react";

function Card() {
    const defaultCollection = {
        "route": "969",
        "destination": {
            "en": "Causeway Bay (Moreton Terrace)",
            "tc": "銅鑼灣 (摩頓台)",
            "sc": "铜锣湾 (摩顿台)"
        },
        "savedStation": {
            "en": "Ching Choi House Tin Ching Estate, Tin Wah Road",
            "sc": "天晴邨晴彩楼, 天华路",
            "tc": "天晴邨晴彩樓, 天華路"
        },
        "stopId": "002059",
        "seq": 6,
        "co": "CTB",
        "direction": "O",
        "routeId": ""
    }

    const collectionExist = localStorage.getItem("collections")
    const[isCollectionExist, setIsCollectionExist] = useState(collectionExist !== null)
    const {t, i18n} = useTranslation();
    const lang = i18n.language
    const busCollection = useContext(BusCollectionContext)
    let defaultRoute = []
    if (!isCollectionExist){
        defaultRoute.push(defaultCollection)
    }else{
        defaultRoute.push(...busCollection.busCollectionList())
    }

    const groupByRouteAndDirection = defaultRoute.reduce((acc, cur) => {
        const key = `${cur.co}-${cur.route}-${cur.direction}`
        if (!acc[key]){
            acc[key] = []
        }
        acc[key].push(cur)
        return acc
    }, {})

    const [busRoute, setBusRoute] = useState(defaultRoute)
    // const [toggle, setToggle] = useState(Array(busRoute.length).fill(false))
    const [isLoading, setIsLoading] = useState(true)
    const [busArrivalTime, setBusArrivalTime] = useState({})
    console.log("card",busArrivalTime)
    // const textRef = useRef(null)
    const [error, setError] = useState(null);


    const fetchData = async (busRoute) => {
        try{
            const requests = busRoute.map(route => {
                if (route.co === "KMB") {
                    return api.getCoEtaData(route.route, route.direction, "KMB").then(resp => {
                        return {
                            [route.co + "-" + route.route + "-" + route.stopId] : resp
                        }
                    })
                }else if (route.co === "CTB") {
                    return api.getCTBEtaData(route.stopId, route.route).then(resp => {
                        return {
                            [route.co + "-" + route.route + "-" + route.stopId] : resp
                        }
                    })
                }else if (route.co === "GMB"){
                    return api.getGMBEtaData(route.seq, route.routeId, route.direction).then(resp => {
                        return {
                            [route.co + "-" + route.route + "-" + route.stopId] : resp
                        }
                    })
                }else if (route.co === "NLB"){
                    return api.getNLBEtaData(route.routeId, route.stopId).then(resp => {
                        return {
                            [route.co + "-" + route.route + "-" + route.stopId]: resp
                        }
                    })
                }else{
                    return {}
                }
            })

            return await Promise.all(requests)
            // setBusArrivalTime(data)
        }catch (e) {
            throw e
        }
    }
    useEffect(() => {
        if (isLoading) {
            fetchData(busRoute).then(resp => {
                const dicMap = resp.reduce((acc, cur) => {
                    return {...acc, ...cur}
                }, {})
                setBusArrivalTime(dicMap)
                setIsLoading(false)
            }).catch(setError)
        }
    }, [busRoute,isLoading])
    //
    useEffect(()=>{
        const internal = setInterval(()=> fetchData(busRoute).then(resp => {
            const dicMap = resp.reduce((acc, cur) => {
                return {...acc, ...cur}
            }, {})
            if (Object.keys(dicMap).length !== 0){
                setBusArrivalTime(dicMap)
                setIsLoading(false)
            }
        }), 10000);

        return () => {
            clearInterval(internal)
        };
    }, [busRoute])

    if (error) {
        throw error
    }

    function toggleAddedCollection(collection) {
        // console.log("collection", collection)
        // return ;
        if (Array.isArray(collection)) {
            console.log("collection", collection)
            let tmpBusRoute = [...busRoute]
            for (const c of collection) {
                tmpBusRoute = tmpBusRoute.filter(bus => !(bus.route === c.route && bus.co === c.co && bus.stopId === c.stopId))
            }
            console.log("tmpBusRoute", tmpBusRoute)
            busCollection.removeRoute(collection);
            setBusRoute(tmpBusRoute);
        }else{
            if (busCollection.isAddedToCollection(collection.co, collection.route, collection.stopId)) {
                busCollection.removeRoute(collection);
                setBusRoute(busRoute.filter(bus => !(bus.route === collection.route && bus.co === collection.co && bus.stopId === collection.stopId)));
            }
        }
    }

    function addDefaultStationToCollection() {
        busCollection.addBusCollection(defaultCollection)
        setIsCollectionExist(true)
    }


    if (!isCollectionExist) {
        return (
            <div>
                <div className='h-6'></div>
                <p className='font-quickSand text-font-757575'>{t('bus_recommend_for_you')}</p>
                <div className='h-3'></div>
                <CardComponent busColor={busColor(defaultCollection.co)}
                               key={0}
                               collection={[defaultCollection]}
                               isLoading={isLoading}
                               busArrivalTime={busArrivalTime}
                               lang={lang}
                               showAddedCollection={true}
                               addDefaultStationToCollection={addDefaultStationToCollection}
                               toggleCollectionEvent={toggleAddedCollection}/>
            </div>)
    }

    return (
        <div>
            <div className='h-6'></div>
            <p className='font-quickSand text-font-757575'>{t('your_saved_stations')}</p>
            <div className='h-3'></div>
            {
                Object.entries(groupByRouteAndDirection).map(([key, value], index) => {
                    return <CardComponent busColor={busColor(value[0].co)}
                                          key={index}
                                          collection={value}
                                          isLoading={isLoading}
                                          busArrivalTime={busArrivalTime}
                                          lang={lang}
                                          showAddedCollection={false}
                                          toggleCollectionEvent={toggleAddedCollection}/>
                })
            }

        </div>
    )
}


    export default Card
