import {busColor} from "../../utils/busColor";

function LineDetailHeader(props) {
    const co = props.co

    return (
        <div className='bg-white w-full'>
            <div className='flex flex-row items-center p-3 h-14 w-full'>
                <svg className='h-6 w-6' viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.825 9L9.425 14.6L8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825Z" fill="#1C1B1F"/>
                </svg>

                <div className='pl-2'>
                    <svg className='h-6 w-6' viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2 19C1.71667 19 1.47917 18.9042 1.2875 18.7125C1.09583 18.5208 1 18.2833 1 18V15.95C0.7 15.6167 0.458333 15.2458 0.275 14.8375C0.0916667 14.4292 0 13.9833 0 13.5V4C0 2.61667 0.641667 1.60417 1.925 0.9625C3.20833 0.320833 5.23333 0 8 0C10.8667 0 12.9167 0.308333 14.15 0.925C15.3833 1.54167 16 2.56667 16 4V13.5C16 13.9833 15.9083 14.4292 15.725 14.8375C15.5417 15.2458 15.3 15.6167 15 15.95V18C15 18.2833 14.9042 18.5208 14.7125 18.7125C14.5208 18.9042 14.2833 19 14 19H13C12.7167 19 12.4792 18.9042 12.2875 18.7125C12.0958 18.5208 12 18.2833 12 18V17H4V18C4 18.2833 3.90417 18.5208 3.7125 18.7125C3.52083 18.9042 3.28333 19 3 19H2ZM2 8H14V5H2V8ZM4.5 14C4.91667 14 5.27083 13.8542 5.5625 13.5625C5.85417 13.2708 6 12.9167 6 12.5C6 12.0833 5.85417 11.7292 5.5625 11.4375C5.27083 11.1458 4.91667 11 4.5 11C4.08333 11 3.72917 11.1458 3.4375 11.4375C3.14583 11.7292 3 12.0833 3 12.5C3 12.9167 3.14583 13.2708 3.4375 13.5625C3.72917 13.8542 4.08333 14 4.5 14ZM11.5 14C11.9167 14 12.2708 13.8542 12.5625 13.5625C12.8542 13.2708 13 12.9167 13 12.5C13 12.0833 12.8542 11.7292 12.5625 11.4375C12.2708 11.1458 11.9167 11 11.5 11C11.0833 11 10.7292 11.1458 10.4375 11.4375C10.1458 11.7292 10 12.0833 10 12.5C10 12.9167 10.1458 13.2708 10.4375 13.5625C10.7292 13.8542 11.0833 14 11.5 14ZM2.45 3H13.65C13.4 2.71667 12.8625 2.47917 12.0375 2.2875C11.2125 2.09583 9.88333 2 8.05 2C6.26667 2 4.9625 2.10417 4.1375 2.3125C3.3125 2.52083 2.75 2.75 2.45 3ZM4 15H12C12.55 15 13.0208 14.8042 13.4125 14.4125C13.8042 14.0208 14 13.55 14 13V10H2V13C2 13.55 2.19583 14.0208 2.5875 14.4125C2.97917 14.8042 3.45 15 4 15Z"
                            fill="#1C1B1F"/>
                    </svg>
                </div>

                <div className='pl-2'>
                    <span
                        className={`h-6 w-6 ${busColor(co)} text-font-212121 font-bold py-1 px-3 rounded-lg text-base`}>{props.route.route}</span>
                </div>
                <div className='w-full pl-2'>
                    <span className="text-black font-bold font-quickSand text-base">{props.route.name}</span>
                </div>
            </div>
        </div>
    )
}

export default LineDetailHeader
