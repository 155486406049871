import {useNavigate, useLocation} from "react-router-dom";
import LineDetailHeader from "../components/LineDetail/LineDetailHeader";
import {useContext, useEffect, useState} from "react";
import haversine from "haversine";
import {findNearest} from "geolib";
import {BusCollectionProvider} from "../store/BusCollection";
import DbContext from "../context/DbContext";
import {api} from "../libs/api";
import CTBStations from "../components/LineDetail/CTBStations";
import KMBStations from "../components/LineDetail/KMBStations";
import GMBStations from "../components/LineDetail/GMBStations";
import {useTranslation} from "react-i18next";
import {location} from "../libs/location";
import NLBStations from "../components/LineDetail/NLBStations";
import {busColor} from "../utils/busColor";
import BuddyFonts from "../components/fonts/BuddyFonts";

function LineDetail() {
    const { t, i18n} = useTranslation();
    const locationUtil = useLocation();
    const query = new URLSearchParams(locationUtil.search);
    const co = query.get('co');
    const route = query.get('route');
    const direction = query.get('direction');
    const destination = query.get('destination');
    const routeId = query.get("routeId"); // for nlb bus only
    const seq = query.get("defaultSeq"); // for nlb bus only
    const lang = i18n.language

    const dbContext = useContext(DbContext)
    // const [busArrivalTime, setBusArrivalTime] = useState([]);
    const [stopList, setStopList] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [closedStop, setClosedStop] = useState({seq: "1"});
    const loadingMap = Array(10).fill(0);
    const [error, setError] = useState(null);

    useEffect(()=>{
        const stopDB = dbContext.stopDB
        if (stopDB == null){
            return
        }
        const fetchData = async () => {
            try{
                // const eta = await  api.getCoEtaData(route, direction, co)
                let stopSeq
                if (co === "NLB"){
                    stopSeq = await api.getNLBStopList(route, routeId, direction, co)
                } else{
                    stopSeq = await api.getCoStopList(route, direction ,co)
                }
                // console.log("eta", eta)
                const localStopList = stopSeq.map(stop => {
                    const key = co + '-' + stop.stopId
                    if (stopDB.hasOwnProperty(key)) {
                        return {
                            bound: stop.bound,
                            co: stop.co,
                            route: stop.route,
                            seq: stop.seq,
                            name: stopDB[key].name,
                            stopId: stop.stopId,
                            lat: stopDB[key].lat,
                            long: stopDB[key].long,
                            routeId: stop.routeId ? stop.routeId : ""  // only for gms bus
                        };
                    }
                    return {}
                });
                setStopList(localStopList)
                // setBusArrivalTime(eta)
                // console.log(busEtaList.data)
                // get current position
                if (seq) {
                    setClosedStop({seq: seq});
                }else{
                    const currentLocation = location.getCurrentLocation();
                    // let minDistance = Infinity;
                    let closedStop = {};
                    const rangeInMeters = 10;
                    const parsedLocations = localStopList.filter((stop) => {
                        const location = {latitude: parseFloat(stop.lat), longitude: parseFloat(stop.long)}
                        const distance = haversine(currentLocation, location);
                        return distance < rangeInMeters;
                    }).map((stop) => {
                        return {latitude: parseFloat(stop.lat), longitude: parseFloat(stop.long), stop: stop};
                    })
                    if (parsedLocations.length > 0){
                        const closedLocation = findNearest(currentLocation, parsedLocations);
                        closedStop = closedLocation.stop;
                        setClosedStop({seq: closedStop.seq});
                    }
                }

                setLoading(false)

            }catch (error){
                // use deny locationUtil permission
                if (error.code === 1) {
                    setClosedStop({seq: "1"});
                    setLoading(false)
                }else {
                    throw error
                    //setError(error)
                    // console.error(error);
                    // setLoading(true);
                }
            }
        }
        if (isLoading){
            fetchData().catch(setError)
        }
    }, [route, routeId, direction, co, isLoading, dbContext, seq])


    const navigate = useNavigate();

    function backToPreviousPage(){
        navigate(-1)
    }

    if (error) {
        throw error
    }

    function renderStation() {
        if (co === "CTB"){
            return <CTBStations stopList={stopList} defaultStop={closedStop.seq} route={route} direction={direction}/>
        }else if (co === "KMB") {
            return <KMBStations stopList={stopList} defaultStop={closedStop.seq} route={route} direction={direction}/>
        }else if (co === "GMB") {
            return <GMBStations stopList={stopList} defaultStop={closedStop.seq} route={route} direction={direction}/>
        }else if (co === "NLB"){
            return <NLBStations stopList={stopList} defaultStop={closedStop.seq} route={route} direction={direction} routeId={routeId}/>
        }
    }

    if (!isLoading){
        return (
            <div className='flex flex-col'>
                <div onClick={backToPreviousPage} className='sticky top-0 p-4 bg-white z-10  max-w-sm'>
                    <LineDetailHeader route={{...stopList.slice(-1)[0], name: stopList.slice(-1)[0].name[lang]}} co={co}/>
                </div>
                <div className='p-4 max-w-sm'>
                <p className={`${BuddyFonts.Body1} text-font-757575`}>{t('add_a_stop')}</p>
                    <div className='h-6'></div>
                    <BusCollectionProvider>
                        {renderStation()}
                    </BusCollectionProvider>
                </div>
            </div>
        )
    } else {
        // for loading page
        return (
            <div className='flex flex-col'>
                <div onClick={backToPreviousPage} className='sticky top-0 p-4 bg-white z-10  max-w-sm'>
                    <LineDetailHeader route={{route: route, name: destination}} co={co}/>
                </div>
                <div className='p-4 max-w-sm'>
                    <div className='h-6'></div>
                    <p className={`${BuddyFonts.Body1} text-font-757575`}>{t('add_a_stop')}</p>
                    <div className='h-6'></div>
                    <div className='h-screen'>
                        <ul>{
                            loadingMap.map((_, index) => {
                                const roundedUp = index === 0 ? 'rounded-t-lg':''
                                const roundedBottom = index === loadingMap.length-1 ? 'rounded-b-lg':''
                                const lastOneHigh = index === loadingMap.length-1 ? 'max-h-5':''
                                return <li key={index}>
                                    <div className='flex flex-row'>
                                        <div className='relative w-full '>
                                            <div className={`absolute w-3.5 h-full ${busColor(co)} ${roundedUp} ${roundedBottom} ${lastOneHigh}`}></div>
                                            <div className={`absolute top-1 left-[3px] rounded-full bg-white w-2 h-2`}></div>
                                            <div className='ml-6 h-10 rounded-lg animate-pulse '>
                                                <div className='rounded-lg mt-2 h-10 bg-gray-300'>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            })
                        }</ul>

                    </div>
                </div>
            </div>

        )
    }
}

export default LineDetail
