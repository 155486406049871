import {useState} from "react";
import {useTranslation} from "react-i18next";
import { Trans } from "react-i18next";
import {Button} from "@material-tailwind/react";
import BuddyFonts from "../fonts/BuddyFonts";
function StationDetail(props) {
    // 管理弹窗的显示状态
    const {t, i18n} = useTranslation()
    const [toggle, setToggle] = useState(false)
    const arrowIcon = toggle === true ? 'fa-chevron-up' : 'fa-chevron-down'
    const isFavorite = props.isFavorite
    const busArrivalTime = props.busArrivalTime.filter((arrivalTime) => arrivalTime.eta !== "" && arrivalTime.eta !== null)
    console.log("arrivalTime", busArrivalTime)
    const lang = i18n.language
    const showAvailableTime = busArrivalTime.filter((arrivalTime) => arrivalTime.eta !== "" && arrivalTime.eta !== null).length > 0
    console.log("showAvailableTime", showAvailableTime)

    function getMinutesAhead(eta){
        const etaDate = new Date(eta);
        const now = new Date();
        const diffInMilliseconds = etaDate - now;
        return Math.round(diffInMilliseconds / 1000 / 60);
    }

    function formatTime(eta) {
        const date = new Date(eta);
    
        let hours = date.getHours();
        const minutes = date.getMinutes();
      
        const ampm = hours >= 12 ? 'pm' : 'am';
      
        // 将24小时格式转换为12小时格式
        hours = hours % 12;
        hours = hours ? hours : 12; // 小时为0时，显示为12
      
        // 格式化分钟
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
      
        return `${hours}:${formattedMinutes} ${ampm}`;
      }


    const handleToggleEvent = (event) => {
        if (busArrivalTime.length < 0){
            return
        }
        if (getMinutesAhead(busArrivalTime[0].eta) === "empty"){
            return
        }
        setToggle(!toggle)
        event.stopPropagation();
    };

    function renderArrivalTime() {
        if (busArrivalTime.length === 0){
            return
        }
        const min = (getMinutesAhead(busArrivalTime[0].eta) < 0 ? "-" : getMinutesAhead(busArrivalTime[0].eta))  
        return (
            <p className={`pl-3 ${BuddyFonts.Body1} text-font-757575`}>
                <Trans i18nKey="next_arrival_time" values={{ min: min }} components={{1: <span className={`pl-1 ${BuddyFonts.Body1} text-[#000000]`}/> }}/>
            </p>
        )
    }


    return (
        <div className='relative'>
            <div className='flex flex-col border rounded p-3 border-E0E0E0 w-full' key={props.station.route}>
                <p>{props.station.name}</p>
                {
                    !showAvailableTime && <p className={`text-font-757575 ${BuddyFonts.Body1}`}>
                        {t('no_arrival_time')}
                    </p>
                }
                {
                    toggle === true && showAvailableTime ?
                        <div onClick={handleToggleEvent} className="mt-2">
                            {
                                <div className="flex flex-col">
                                <div className="w-full flex flex-row">
                                    <div className="flex flex-row h-[20px] w-[20px] items-center justify-center">
                                        <i className={`fa-solid ${arrowIcon} text-[#757575]`}></i>
                                    </div>
                                    <p className={`pl-3 ${BuddyFonts.Body1} text-font-757575`}>{t('other_arrival_time')}</p>
                                </div>
                                {
                                    busArrivalTime.sort((a, b) => getMinutesAhead(a.eta) - getMinutesAhead(b.eta)).map((bus, index) => {
                                        const time = formatTime(bus.eta)
                                        console.log("time", bus.eta)
                                        return <p key={index} className='text-base tracking-wider font-quickSand text-[#424242] mt-3 ml-8'> {time} </p>
                                    })
                                }
                            </div>
                            }
                        </div>
                        :
                        <div onClick={handleToggleEvent} className="mt-2">
                            {
                                showAvailableTime &&
                                <div className='flex flex-row'>
                                <div className="flex h-[20px] w-[20px] items-center justify-center">
                                    <i className={`fa-solid ${arrowIcon} text-[#757575]`}></i>
                                </div>
                                    {
                                        renderArrivalTime()
                                    }
                                </div>
                            }
                        </div>

                }
                {
                    busArrivalTime.length > 0 && busArrivalTime[0].remark[lang] !== "" &&
                    <div className="mt-2 border rounded p-1.5 bg-[#F4F4F4]">
                        <p className="font-quickSand text-font-757575 text-xs">{busArrivalTime[0].remark[lang]}</p>
                    </div>
                }
                {
                    isFavorite === true ?
                        <Button onClick={()=>props.onHandleFavoriteEvent(props.station)}
                                className='rounded-full mt-4' variant='outlined'>
                            <p className='text-sm font-semibold font-quickSand text-fnt-212121'>- {t('remove_collection')}</p>
                        </Button>
                        :
                        <Button onClick={()=>props.onHandleFavoriteEvent(props.station)} className='rounded-full mt-4'>
                            <p className='text-sm font-semibold font-quickSand text-white'>+ {t('add_to_collection')}</p>
                        </Button>
                }
            </div>
        </div>

    )
}

export default StationDetail
