import StationDetail from "../LineDetail/StationDetail";
import {useContext, useEffect, useState} from "react";
import {Dialog} from "@material-tailwind/react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import BusCollectionContext from "../../store/BusCollection";
import DbContext from "../../context/DbContext";
import {busColor} from "../../utils/busColor";

function Station({stations, busArrivalTime, lang, handleClick, selectedSeq, route, co}) {
    const {t} = useTranslation()
    const [dialog, setDialog] = useState(false)
    const dbContext = useContext(DbContext)
    const routes = dbContext.routeDB;
    const stopDB = dbContext.stopDB;

    const busCollection = useContext(BusCollectionContext)
    const s = stations.find(s => s.seq.toString() === selectedSeq.toString())
    const [isFavorite, setIsFavorite] = useState(busCollection.isAddedToCollection(co, route, s.stopId))
    const navigate = useNavigate()

    function handleFavoriteEvent(station) {
        const isAdded = busCollection.isAddedToCollection(co, route, station.stopId)
        if (isAdded) {
            busCollection.removeRoute({co: co, route: route, stopId: station.stopId})
            setIsFavorite(false)
            setDialog(!dialog)
            return
        }

        const routeDetail = routes.find(routeDetail => routeDetail.co === co && routeDetail.route === route && routeDetail.direction === station.bound)
        const key = co + '-' + station.stopId
        if (routeDetail === null || !stopDB.hasOwnProperty(key)){
            setDialog(!dialog)
            return
        }
        const collectionDetail = {
            route: route,
            destination: routeDetail.dest,
            savedStation: stopDB[key].name,
            stopId: stopDB[key].stopId,
            seq: station.seq,
            co: co,
            direction: routeDetail.direction,
            routeId: routeDetail.route_id ? routeDetail.route_id : ""  // only for gms bus
        }
        busCollection.addBusCollection(collectionDetail)
        setIsFavorite(true)
        setDialog(!dialog)
    }

    function handleDialogOpen() {
        setDialog(!dialog)
    }

    function routeToHomePage() {
        navigate('/')
    }

    useEffect(() => {
        const element = document.getElementById('scrollTo'+selectedSeq);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [selectedSeq]);

    function renderStationName(station){
        const stationName = station.name[lang]
        if (lang === "en"){
            return stationName.charAt(0).toUpperCase() + stationName.slice(1).toLowerCase()
        }
        return stationName
    }

    return (
        <div className='flex flex-col w-full'>
            <ul>
                {
                    stations.map((station, index) => {
                        const roundedUp = index === 0 ? 'rounded-t-lg' : ''
                        const roundedBottom = index === stations.length - 1 ? 'rounded-b-lg' : ''
                        const lastOneHigh = index === stations.length - 1 ? 'max-h-5' : ''
                        return <li key={station.seq} onClick={()=>handleClick(station)}>
                            <div className='flex flex-row'>
                                <div className='relative w-full'>
                                    <div className={`absolute w-3.5 h-full ${busColor(co)} ${roundedUp} ${roundedBottom} ${lastOneHigh}`}></div>
                                    <div className={`absolute top-1 left-[3px] rounded-full bg-white w-2 h-2 ${selectedSeq === station.seq.toString() ? 'border-[2px] border-gray-500/30' : ''} `}></div>
                                    {
                                        selectedSeq.toString() === station.seq.toString() ?
                                            <div>
                                                <div className='pl-5 w-full pb-6' id={`scrollTo${selectedSeq}`}>
                                                    <StationDetail station={{...station, name: renderStationName(station)}}
                                                                   busArrivalTime={ busArrivalTime }
                                                                   onHandleDialog={handleDialogOpen}
                                                                   onHandleFavoriteEvent={handleFavoriteEvent}
                                                                   isFavorite={busCollection.isAddedToCollection(co, route, station.stopId)}
                                                    />
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <div className='pl-5 w-full pb-6'>
                                                    <p className='font-quickSand text-sm text-left break-words pr-5'>
                                                        {index+1 + "." + renderStationName(station)}
                                                    </p>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </li>
                    })
                }
            </ul>

            <Dialog open={dialog} handler={handleDialogOpen}>
                <div className="flex flex-col items-center bg-white rounded-lg shadow-lg max-w-sm w-full p-4 m-auto">
                    <div className='mt-3.5 ml-auto'>
                        <button onClick={handleDialogOpen} className="text-gray-600">
                            <i className="fa-solid fa-xmark fa-xl"></i>️
                        </button>
                    </div>

                    <div className='mt-3.5'>
                        <img src='/img/confirmBox/undraw_order_confirmed_re_g0ifadded.png' alt='successful'/>
                    </div>
                    <h2 className="text-2xl text-black font-quickSand font-bold mt-4 mb-4">
                        {isFavorite === true ? t('collection_added') : t('collection_removed')}</h2>
                    <button
                        onClick={routeToHomePage}
                        className="text-lg font-bold bg-black text-white p-4 px-4 rounded-full w-full mt-3 mb-5 font-quickSand">
                        {t('view_collection')}
                    </button>
                </div>
            </Dialog>

        </div>
    )
}

export default Station
