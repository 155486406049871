const resources = {
    en:{
        translation:{
            add_to_collection: 'Add to collection',
            remove_collection: 'Remove from collection',
            view_collection: 'VIEW COLLECTION',
            collection_removed:'Removed',
            collection_added:'Added',
            search_bus_to_collect: 'Search for bus to collect',
            bus_recommend_for_you: 'Bus recommend for you',
            setting: 'Setting',
            language_menu:'language',
            language_content:'English',
            select_your_language: 'Select language',
            select_your_language_cancel:'Cancel',
            add_a_stop: 'Select a stop to add',
            next_arrival_time: 'Scheduled at <1>{{min}}</1> min',
            other_arrival_time: 'Scheduled at',
            no_arrival_time: 'No schedule available',
            minute_unit:'min',
            saved_station: 'saved station',
            your_saved_stations: 'Collected stations',
            collection_empty_promt: 'You could add your favorite bus route by searching in the search box',
            location_permission: 'Location permit',
            location_permission_on: 'On',
            location_permission_off: 'Off',
            no_bus_added: 'No bus station added',
            search_bus_to_add: 'Search to add an station',
            api_error: "API Error",
            api_error_try_later: "Please try to restart the APP again later.",
            refresh:"Refresh",
            back_to_home_page:"Back to home page",
            to_stop: "Arrive at",
            remove: "Remove",
            feedback_promt: "Your feedback will determine our next iteration. Feel free to share your thoughts!",
            go_to_feedback: "Share your feedback on Form",
            add_to_home_screen_title: "How to add StationBuddy to home screen?",
            add_to_home_screen_step1: "Tap share button <1></1> at the bottom of safari or on the right of the address bar",
            add_to_home_screen_step2: "Scroll down to find \"add to home screen\" button"
              
        }
    },
    tc:{
        translation: {
            add_to_collection: '加入收藏',
            remove_collection: '取消收藏',
            view_collection: '查看收藏',
            collection_removed:'已取消',
            collection_added:'已添加',
            search_bus_to_collect: '搜尋巴士以收藏',
            bus_recommend_for_you: '為你推薦嘅巴士',
            setting: '設置',
            language_menu:'語言',
            language_content:'繁體中文',
            select_your_language: '選擇你的語言',
            select_your_language_cancel:'取消',
            add_a_stop: '收藏你常用嘅巴士站',
            next_arrival_time: '下一班車係 <1>{{min}}</1> 分鐘後到達',
            other_arrival_time: '其他班次',
            no_arrival_time: '暫時沒有班次',
            minute_unit:'分鐘',
            saved_station: '收藏嘅巴士站',
            your_saved_stations: '你已收藏嘅巴士站',
            collection_empty_promt: '通過搜索你常用的巴士，然後收藏巴士站在此，後續就無需再反復搜尋!',
            location_permission: '開啟定位',
            location_permission_on: '開啟',
            location_permission_off: '關閉',
            no_bus_added: '沒有收藏嘅巴士',
            search_bus_to_add: '可以係搜索巴士頁面添加常用巴士站',
            api_error: "伺服器發生錯誤",
            api_error_try_later: "嘗試重新開啟App",
            refresh:"刷新",
            back_to_home_page:"返回主頁",
            to_stop: "到達",
            remove: "取消收藏",
            arrival_suffix: "分鐘後到達",
            feedback_promt: "你的反饋會決定我們下一次的迭代。歡迎分享你的反饋！",
            go_to_feedback: "去GOOGLE FORM分享反饋",
            add_to_home_screen_title: "如何將 StationBuddy 添加到主屏幕？",
            add_to_home_screen_step1: "點擊 Safari 底部或 Chrome 地址欄右側的分享按鈕 <1></1>",
            add_to_home_screen_step2: "向下滾動找到「添加到主屏幕」按鈕"
        }
    },
    sc:{
        translation:{
            add_to_collection: '加入收藏',
            remove_collection: '取消收藏',
            view_collection: '查看收藏',
            collection_removed:'已取消',
            collection_added:'已添加',
            search_bus_to_collect: '搜索巴士以收藏',
            bus_recommend_for_you: '为你推荐的巴士',
            setting: '设定',
            language_menu:'语言',
            language_content:'简体中文',
            select_your_language: '选择你的语言',
            select_your_language_cancel:'取消',
            add_a_stop: '收藏你常用的巴士站',
            next_arrival_time: '下一班车在 <1>{{min}}</1> 分钟后到达',
            other_arrival_time: '其他班次',
            no_arrival_time: '暂时没有班次',
            minute_unit:'分钟',
            saved_station: '收藏的巴士站',
            your_saved_stations: '你已收藏的巴士站',
            collection_empty_promt: '通过搜索你常用的巴士，然后收藏站点到此，后续就无需再反复搜索',
            location_permission: '开启定位',
            location_permission_on: '开启',
            location_permission_off: '关闭',
            no_bus_added: '没有收藏的巴士',
            search_bus_to_add: '可以到搜索巴士页面添加常用的巴士站',
            api_error: "服务器发生错误",
            api_error_try_later: "尝试重新开启App",
            refresh:"刷新",
            back_to_home_page:"返回主页",
            to_stop: "到达",
            remove: "取消收藏",
            arrival_suffix: "分钟后到达",
            feedback_promt: "你的反馈会决定我们下一次的迭代。欢迎分享你的反馈！",
            go_to_feedback: "去GOOGLE FORM分享反馈",
            add_to_home_screen_title: "如何将StationBuddy添加到主屏幕？",
            add_to_home_screen_step1: "点击 Safari 底部或 Chrome 地址栏右侧的分享按钮 <1></1>",
            add_to_home_screen_step2: "向下滚动以找到 “添加到主屏幕” 按钮",
        }
    }
}

export default resources
