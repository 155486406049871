import axios from "axios";

const GOV_ENDPOINT = "https://data.etabus.gov.hk"
const GOV_ENDPOINT_RT = "https://rt.data.gov.hk"
const GOV_ENDPOINT_GMS = "https://data.etagmb.gov.hk"
async function getCTBStopList(route, direction, co){
    const finalDir = direction === "I" ? "inbound" : "outbound";
    const stopList = await axios.get(`${GOV_ENDPOINT_RT}/v2/transport/citybus/route-stop/ctb/${route}/${finalDir}`)
    return stopList.data.data.map(stop => {
        return {
            co: co,
            bound: stop.dir,
            seq: stop.seq,
            stopId: stop.stop,
            route: stop.route
        }
    })
}

async function getGMSStopList(route, direction, co) {
    const routeMappingResp = await axios.get(`${GOV_ENDPOINT_GMS}/route`)
    const routes = routeMappingResp.data.data.routes
    let region
    regionLoop:
    for (const r in routes) {
        for (const routeNum of routes[r]) {
            if (route.toString() === routeNum.toString()) {
                region = r
                break regionLoop
            }
        }
    }

    const busTripResp = await axios.get(`${GOV_ENDPOINT_GMS}/route/${region}/${route}`)
    const busTrip = busTripResp.data.data[0]
    const routeId = busTrip.route_id
    const finalDir = direction === "I" ? "2" : "1";
    const busStopResp = await axios.get(`${GOV_ENDPOINT_GMS}/route-stop/${routeId}/${finalDir}`)
    return busStopResp.data.data.route_stops.map(stop => {
        return {
            co: co,
            bound: finalDir === "2" ? "I" : "O",
            seq: stop.stop_seq,
            stopId: stop.stop_id,
            route: route,
            routeId: routeId
        }
    })
}


export const api = {

    getCoStopList: async (route, direction, co) => {
        if (co === "KMB") {
            const finalDir = direction === "I" ? "inbound" : "outbound";
            // route + direction + service_type
            const stopList = await axios.get(`${GOV_ENDPOINT}/v1/transport/kmb/route-stop/${route}/${finalDir}/1`)
            return stopList.data.data.map(stop => {
                return {
                    co: co,
                    bound: stop.bound,
                    seq: stop.seq,
                    stopId: stop.stop,
                    route: stop.route
                }
            })
        }else if (co === "CTB") {
            return getCTBStopList(route, direction, co)
        } else if (co === "GMB") {
            return getGMSStopList(route, direction, co)
        }

    },
    getNLBStopList: async(route, routeId, direction, co) => {
        const stopList = await axios.get(`${GOV_ENDPOINT_RT}/v2/transport/nlb/stop.php?action=list&routeId=${routeId}`)
        let seq = 1;
        return stopList.data.stops.map(stop => {
            return {
                co: co,
                bound: direction,
                seq: seq++,
                stopId: stop.stopId,
                route: route
            }
        })
    },
    getCoEtaData : async (route, direction, co) =>{
        if (co === "KMB") {
            // route + 55555service_type
            const eta = await axios.get(`${GOV_ENDPOINT}/v1/transport/kmb/route-eta/${route}/1`)
            if (!Array.isArray(eta.data.data)) {
                return []
            }
            return eta.data.data
                .filter(e => e.dir === direction)
                .map(e=> {
                return {
                    co: co,
                    eta: e.eta,
                    etaSeq: e.eta_seq,
                    seq: e.seq,
                    route: e.route,
                    remark: {
                        en: e.rmk_en,
                        tc: e.rmk_tc,
                        sc: e.rmk_sc

                    }
                }
            })
        }else if (co === "CTB") {
            const stopList = await getCTBStopList(route, direction, co)
            const requests = stopList.map(stop =>
                axios.get(`${GOV_ENDPOINT_RT}/v2/transport/citybus/eta/ctb/${stop.stopId}/${route}`)
                    .then(resp => ( {stopId: stop.stopId, data: resp.data} ))
            )
            const results = await Promise.all(requests)
            return results.reduce((agg, resp) =>{
                const data = resp.data.data
                // const stopId = resp.stopId
                if (data.length === 0){
                    return agg
                }else{
                    for(const etaData of data){
                        if(etaData.dir === direction){
                            agg.push({
                                co: co,
                                eta: etaData.eta,
                                etaSeq: etaData.eta_seq,
                                seq: etaData.seq,
                                route: route
                            })
                        }
                    }
                    return agg
                }
            }, [])
        } else if (co === "GMB") {

        }
    },
    getCTBEtaData : async (stopId, route) =>{
        const resp = await axios.get(`${GOV_ENDPOINT_RT}/v2/transport/citybus/eta/ctb/${stopId}/${route}`)
        if (!Array.isArray(resp.data.data)) {
            return []
        }
        return resp.data.data.map(eta => {
            return {
                eta: eta.eta,
                etaSeq: eta.eta_seq,
                seq: eta.seq,
                route: eta.route,
                dir: eta.dir,
                remark: {
                    en: eta.rmk_en,
                    tc: eta.rmk_tc,
                    sc: eta.rmk_sc

                }
            }
        })
    },
    getGMBEtaData: async (stopSeq, routeId, direction) => {
        const finalDir = direction === "I" ? "2" : "1";
        const etaResp = await axios.get(`${GOV_ENDPOINT_GMS}/eta/route-stop/${routeId}/${finalDir}/${stopSeq}`)
        if (!Array.isArray(etaResp.data.data.eta)) {
            return []
        }
        return etaResp.data.data.eta.map(eta => {
            return {
                eta:eta.timestamp,
                etaSeq: eta.eta_seq,
                remark: {
                    en: eta.remarks_en,
                    tc: eta.remarks_tc,
                    sc: eta.remarks_sc
                }
            }
        });
        // const resp
    },
    getNLBEtaData: async (routeId, stopId) =>{
        const etaResp = await axios.get(`${GOV_ENDPOINT_RT}/v2/transport/nlb/stop.php?action=estimatedArrivals&routeId=${routeId}&stopId=${stopId}&language=en`)
        if (!Array.isArray(etaResp.data.estimatedArrivals)) {
            return []
        }
        return etaResp.data.estimatedArrivals.map(eta=>{
            return {
                eta: eta.estimatedArrivalTime,
                remark:{
                    en: "",
                    tc: "",
                    sc: ""
                }
            }
        })

    }
}
