import {useTranslation} from "react-i18next";
import BorderGradientButton from '../buttons/BorderGradientButton';
import NoBorderGradientButton from '../buttons/NoBorderGradientButton';
function ErrorPage() {
    const {t} = useTranslation()

    function reloadPage() {
        window.location.reload()
    }

    function backToHome() {
        window.location.href = "/"
    }
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-white z-10 p-6">
            <div className="flex flex-col items-center justify-center">
                <img src="/img/server_error.png" alt="service error"/>
                <p className="font-quickSand text-2xl font-medium mt-2">{t("api_error")}</p>
                <p className="font-quickSand text-base tracking-wider text-font-757575 mt-2">{t("api_error_try_later")}</p>
                <NoBorderGradientButton className="mt-9" onClick={backToHome}>
                    <i class="fa-solid fa-house text-white"></i>
                    <p className="bg-gradient-to-tl from-from-blue to-to-blue border-from-blue text-transparent bg-clip-text text-white font-quickSand text-sm font-bold uppercase ml-2">{t("back_to_home_page")}</p>
                </NoBorderGradientButton>

                <BorderGradientButton className="mt-3 w-full" onClick={reloadPage}>
                    <div className="h-full w-full flex justify-center items-center">
                        <i className="fa-solid fa-rotate bg-gradient-to-tl from-from-blue to-to-blue border-from-blue text-transparent bg-clip-text"></i>
                        <p className="bg-gradient-to-tl from-from-blue to-to-blue border-from-blue text-transparent bg-clip-text text-sm font-bold uppercase ml-2 tracking-wider"
                    >{t("refresh")}</p>
                    </div>
                </BorderGradientButton>


            </div>
        </div>
    )
}

export default ErrorPage
