import {createContext} from "react";
const DbContext = createContext({})

export function DbContextProvider(props){

    const CURRENT_DB_VERSION = '1.0.0'
    const initialDB = props.initialDB
    const routeDB = initialDB.routeDB
    const stopDB = initialDB.stopDB
    const context = {
        routeDB: routeDB,
        stopDB: stopDB,
        version: CURRENT_DB_VERSION
    }

    return <DbContext.Provider value={context}>
        {props.children}
    </DbContext.Provider>
}

export default DbContext
